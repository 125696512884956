<template>
  <div>
    <div
      class="innerContent"
      style="
        height: 94vh;
        width: 100%;
        min-width: 85%;
        max-width: 100%;
        background: var(--v-greyRaised-base) !important;
      "
    >
      <v-row class="pa-2" no-gutters>
        <v-col cols="12" sm="12" md="4" lg="4">
          <v-card class="infoCards ma-1" flat>
            <v-card-text class="ma-0 pa-0">
              <v-list dense class="my-0 py-0">
                <v-subheader style="font-size: 14px">
                  <v-icon class="mr-2" small>handshake</v-icon>
                  Parties <v-spacer></v-spacer>
                  <!-- <v-chip
                    v-if="shipment.consigneeProfile"
                    small
                    @click="viewProfile()"
                  >
                    Profile: {{ shipment.consigneeProfile.systemReference }}
                    <v-icon small color="grey" right>launch</v-icon>
                  </v-chip> -->
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="party of listedParties" :key="party.id">
                  <v-list-item-action class="mr-1">
                    <v-avatar
                      size="30"
                      :color="party.logo ? 'white' : 'secondary'"
                    >
                      <v-img
                        v-if="party.logo"
                        contain
                        :src="party.logo"
                      ></v-img>
                      <h3 v-else>
                        {{ party.name.charAt(0) }}
                      </h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px">
                      {{ party.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 11px"
                      class="text-wrap"
                      v-if="party.linkedValue && shipment[party.linkedValue]"
                    >
                      On behalf of {{ shipment[party.linkedValue].name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      style="font-size: 11px"
                      v-if="shipment[party.roleValue + 'Address']"
                      class="text-wrap"
                    >
                      {{
                        concatenateAddress(
                          shipment[party.roleValue + "Address"]
                        )
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 11px">
                      <v-icon class="mr-1" x-small>groups</v-icon
                      >{{ party.role }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
          <v-card flat class="infoCards ma-1 mt-2">
            <v-card-text class="my-0 py-0">
          <v-row class="pa-1">
            <v-col cols="12" md="6" class="mb-0">
                  <v-list dense class="my-0 py-0" subheader>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">location_on</v-icon>
                      Locations
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item style="height: 45px">
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.originCountry.iso2.toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.originCountry.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Country of Origin
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="shipment.portOfLoadValue"
                      style="height: 45px"
                    >
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.portOfLoadValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.portOfLoadCity }} ({{
                            shipment.portOfLoadValue
                          }})
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Port of Load
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="shipment.portOfDischargeValue"
                      style="height: 45px"
                    >
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.portOfDischargeValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.portOfDischargeCity }} ({{
                            shipment.portOfDischargeValue
                          }})
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Port of Discharge
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="shipment.finalDestinationValue"
                      style="height: 45px"
                    >
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.finalDestinationValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.finalDestinationCity }} ({{
                            shipment.finalDestinationValue
                          }})
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Final Destination
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="shipment.destinationCountry"
                      style="height: 45px"
                    >
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.destinationCountry.iso2.toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ shipment.destinationCountry.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Destination Country
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
            </v-col>
            <v-col cols="12" md="6" class="pl-0 ml-0 mb-0">
                  <v-list dense class="my-0 py-0" subheader>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">event</v-icon>
                      Dates
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item style="height: 30px">
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-icon
                            :color="shipment.etd ? 'primary' : 'grey'"
                            small
                            >schedule</v-icon
                          >
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="shipment.etd">
                          {{ shipment.etd }}
                        </v-list-item-title>
                        <v-list-item-title v-else>- </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Estimated Departure
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item style="height: 30px">
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-icon
                            :color="shipment.atd ? 'primary' : 'grey'"
                            small
                            >schedule</v-icon
                          >
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="shipment.atd">
                          {{ shipment.atd }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Actual Departure
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item style="height: 30px">
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-icon
                            :color="shipment.eta ? 'primary' : 'grey'"
                            small
                            >schedule</v-icon
                          >
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="shipment.eta">
                          {{ shipment.eta }}
                        </v-list-item-title>
                        <v-list-item-title v-else>- </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Estimated Arrival
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item style="height: 30px">
                      <v-list-item-action>
                        <v-avatar size="32">
                          <v-icon
                            :color="shipment.ata ? 'primary' : 'grey'"
                            small
                            >schedule</v-icon
                          >
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="shipment.ata">
                          {{ shipment.ata }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Actual Arrival
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- <v-list
                      v-if="
                        shipment.stackDates && shipment.stackDates.length > 0
                      "
                      dense
                      class="my-0 py-0"
                    >
                      <v-divider></v-divider>
                      <v-subheader style="font-size: 14px">
                        <v-icon class="mr-2" small>date_range</v-icon>
                        Stack Dates
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list-item
                        v-for="stack in shipment.stackDates"
                        :key="stack.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ stack.terminal }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="shipment.reefer && stack.reeferStackStart"
                            style="font-size: 11px"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  :color="
                                    stack.reeferStackStartConfirmed
                                      ? 'success'
                                      : 'orange'
                                  "
                                  v-on="on"
                                  >fiber_manual_record</v-icon
                                >
                              </template>
                              Reefer Stack Start:
                              <span v-if="stack.reeferStackStartConfirmed"
                                >Firm</span
                              ><span v-else>Provisional</span>
                            </v-tooltip>
                            Open:
                            {{
                              $Format.formatDate(stack.reeferStackStart)
                                .dateTimeDefault
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="shipment.reefer && stack.reeferStackFinish"
                            style="font-size: 11px"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  :color="
                                    stack.reeferStackFinishConfirmed
                                      ? 'success'
                                      : 'orange'
                                  "
                                  v-on="on"
                                  >fiber_manual_record</v-icon
                                >
                              </template>
                              Reefer Stack Close:
                              <span v-if="stack.reeferStackFinishConfirmed"
                                >Firm</span
                              ><span v-else>Provisional</span>
                            </v-tooltip>
                            Close:
                            {{
                              $Format.formatDate(stack.reeferStackFinish)
                                .dateTimeDefault
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="!shipment.reefer && stack.dryStackStart"
                            style="font-size: 11px"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  :color="
                                    stack.dryStackStartConfirmed
                                      ? 'success'
                                      : 'orange'
                                  "
                                  v-on="on"
                                  >fiber_manual_record</v-icon
                                >
                              </template>
                              Dry Stack Open:
                              <span v-if="stack.dryStackStartConfirmed"
                                >Firm</span
                              ><span v-else>Provisional</span>
                            </v-tooltip>
                            Open:
                            {{
                              $Format.formatDate(stack.dryStackStart)
                                .dateTimeDefault
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="!shipment.reefer && stack.dryStackFinish"
                            style="font-size: 11px"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  :color="
                                    stack.dryStackFinishConfirmed
                                      ? 'success'
                                      : 'orange'
                                  "
                                  v-on="on"
                                  >fiber_manual_record</v-icon
                                >
                              </template>
                              Dry Stack Close:
                              <span v-if="stack.dryStackFinishConfirmed"
                                >Firm</span
                              ><span v-else>Provisional</span>
                            </v-tooltip>
                            Close:
                            {{
                              $Format.formatDate(stack.dryStackFinish)
                                .dateTimeDefault
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list> -->
                  </v-list>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="mt-0 pt-0">
              <v-card class="infoCards mt-0 mr-0" flat>
                <v-card-text class="ma-0 pa-0">
                  <v-list dense class="my-0 py-0">
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">history</v-icon>
                      Shipment Changes</v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list-item v-if="shipment.shipmentChanges.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey">
                          No Changes
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-for="change in shipment.shipmentChanges"
                      :key="change.id"
                      style="border-bottom: 0.1px solid grey"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon small color="secondary" class="mr-2 pb-1"
                            >event_repeat</v-icon
                          >
                          {{ formatChangeType(change.type) }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="change.locode">
                          <v-chip outlined style="border: none" small>
                            <v-avatar size="20" left>
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${change.locode
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ change.locode }}
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-else-if="change.vessel"
                          class="text-wrap"
                        >
                          <v-icon small color="grey" class="mr-2"
                            >directions_boat</v-icon
                          >
                          {{ change.vessel.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-else-if="change.changedOrganisation"
                          class="text-wrap"
                        >
                          <v-icon small color="grey" class="mr-2"
                            >business</v-icon
                          >
                          Party: {{ change.changedOrganisation.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          class="text-wrap"
                          v-if="change.responsibleOrganisation"
                        >
                          <v-icon small color="grey" class="mr-2"
                            >account_balance</v-icon
                          >
                          Responsible Party:
                          {{ change.responsibleOrganisation.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          class="text-wrap"
                          v-if="change.consigneeProfile"
                        >
                          <v-icon small color="grey" class="mr-2"
                            >handshake</v-icon
                          >
                          Profile:
                          {{ change.consigneeProfile.systemReference }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="change.reason"
                          class="text-wrap"
                        >
                          <v-icon small color="grey" class="mr-2"
                            >article</v-icon
                          >
                          {{ change.reason }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="change.user">
                          <v-chip outlined style="border: none" small>
                            <v-avatar size="16" left :color="'secondary'">
                              <v-icon color="white" x-small>person</v-icon>
                            </v-avatar>
                            {{ change.user.firstname }}
                            {{ change.user.surname }}
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="change.createdAt"
                        >
                          <span style="font-size: 10px; color: grey">
                            {{
                              $Format.formatDate(change.createdAt)
                                .dateTimeDefault
                            }}</span
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pl-0 ml-0 mt-0 pt-0">
              <v-card class="infoCards mt-0 ml-0" flat>
                <v-card-text class="ma-0 pa-0">
                  <v-list dense class="my-0 py-0">
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>cancel</v-icon>
                      Service Failures
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item
                      v-for="failure in shipment.serviceFailures"
                      :key="failure.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-if="failure.shipmentDocumentType">
                          {{
                            failure.shipmentDocumentType.locationDocument.name
                          }}
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          General Service Failure
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          <v-icon color="grey" class="mr-2" small
                            >category</v-icon
                          >
                          {{ failure.serviceFailureCategory.name }}
                          <span v-if="failure.serviceFailureSubcategory"
                            >:
                            {{ failure.serviceFailureSubcategory.name }}</span
                          >
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="failure.createdBy"
                          class="wrap-text"
                        >
                          <v-icon color="grey" small class="mr-2"
                            >person</v-icon
                          >
                          {{ failure.createdBy.firstname }}
                          {{ failure.createdBy.surname }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="
                            failure.responsibleParty ||
                            failure.responsibleOrganisation
                          "
                          class="wrap-text"
                        >
                          <v-icon color="grey" small class="mr-2"
                            >business</v-icon
                          >
                          <span v-if="failure.responsibleOrganisation">{{
                            failure.responsibleOrganisation.name
                          }}</span>
                          <span v-else
                            >Other: {{ failure.responsibleParty }}</span
                          >
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="failure.comment"
                          class="wrap-text"
                        >
                          <v-icon color="grey" small class="mr-2">chat</v-icon>
                          {{ failure.comment }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          class="wrap-text"
                          style="font-size: 11px"
                        >
                          <v-icon color="grey" small class="mr-2">event</v-icon
                          >{{
                            $Format.formatDate(failure.createdAt)
                              .dateTimeDefault
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        shipment.serviceFailures &&
                        shipment.serviceFailures.length == 0
                      "
                    >
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No service failures.</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="my-0 py-0 mr-0">
              <v-card flat class="infoCards ma-0 py-0 pr-0">
                <v-card-text class="ma-0 pa-0">
                  <v-list dense class="my-0 py-0">
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>account_balance</v-icon>
                      Commercial Terms
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item style="height: 30px">
                      <v-list-item-action class="mr-1">
                        <v-icon color="grey" small>currency_exchange</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 12px">
                          {{ shipment.shipmentIncoTerm }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Inco Term
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 30px">
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          :color="
                            shipment &&
                            shipment.contractParty &&
                            shipment.contractParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                          size="24"
                        >
                          <v-img
                            v-if="
                              shipment &&
                              shipment.contractParty &&
                              shipment.contractParty.logo
                            "
                            :src="shipment.contractParty.logo"
                            contain
                          ></v-img>
                          <h3 v-else-if="shipment.contractParty">
                            {{ shipment.contractParty.name.charAt(0) }}
                          </h3>
                          <v-icon v-else small>article</v-icon>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="font-size: 12px"
                          v-if="shipment.contractParty"
                        >
                          {{ shipment.contractParty.name }}
                        </v-list-item-title>
                        <v-list-item-title style="font-size: 12px" v-else>
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Contract Owner
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-icon color="grey" small>location_on</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="font-size: 12px"
                          v-if="shipment.originParty"
                        >
                          {{ shipment.originParty.name }}
                        </v-list-item-title>
                        <v-list-item-title style="font-size: 12px" v-else>
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Term: {{ shipment.originTerm }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle style="font-size: 11px">
                          Origin Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-icon color="grey" small>location_on</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="font-size: 12px"
                          v-if="shipment.freightParty"
                        >
                          {{ shipment.freightParty.name }}
                        </v-list-item-title>
                        <v-list-item-title style="font-size: 12px" v-else>
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Term: {{ shipment.freightTerm }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle style="font-size: 11px">
                          Freight Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-icon color="grey" small>location_on</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="font-size: 12px"
                          v-if="shipment.destinationParty"
                        >
                          {{ shipment.destinationParty.name }}
                        </v-list-item-title>
                        <v-list-item-title style="font-size: 12px" v-else>
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Term: {{ shipment.destinationTerm }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle style="font-size: 11px">
                          Destination Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    
    </v-col>
    <v-col cols="12" md="8">
          <v-row class="pa-1">
            <v-col cols="12" sm="12" >
              <v-card class="infoCards mt-0 pt-0" flat>
                <v-card-text class="ma-0 pa-0">
                  <v-list dense class="my-0 py-0" subheader>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">description</v-icon>
                      Documents
                      <v-spacer></v-spacer>
                      <v-chip>
                        <v-text-field
                          placeholder="Search"
                          class="mb-1"
                          hide-details
                          rounded
                          clearable
                          dense
                          style="width: 300px"
                          v-model="search"
                        ></v-text-field>
                      </v-chip>
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-row class="pa-1">
                      <v-col cols="12" md="3">
                        <v-list dense class="my-0 py-0">
                          <v-subheader style="font-size: 14px">
                            <v-icon class="mr-2" small>insights</v-icon>
                            File Progress
                          </v-subheader>
                          <v-col cols="12" class="text-center">
                            <v-container>
                              <v-row justify="center">
                                <v-col class="text-center">
                                  <v-progress-circular
                                    v-if="performance"
                                    rotate="270"
                                    :size="100"
                                    :width="12"
                                    :value="performance.progress"
                                    :color="
                                      performance.progress == 0
                                        ? 'grey'
                                        : performance.progress < 20
                                        ? 'red'
                                        : performance.progress < 50
                                        ? 'orange'
                                        : performance.progress < 100
                                        ? 'blue'
                                        : 'success'
                                    "
                                  >
                                    {{ performance.progress + "%" }}
                                  </v-progress-circular>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-col>
                          <v-list-item style="height: 30px">
                            <v-list-item-action class="mr-2">
                              <v-avatar
                                size="32"
                                v-if="!shipment.assignedDocumentUser"
                              >
                                <v-icon color="grey"> person </v-icon>
                              </v-avatar>
                              <v-avatar
                                v-else
                                :color="
                                  shipment.assignedDocumentUser.avatar
                                    ? 'white'
                                    : 'secondary'
                                "
                                size="32"
                              >
                                <v-img
                                  v-if="shipment.assignedDocumentUser.avatar"
                                  :src="shipment.assignedDocumentUser.avatar"
                                  contain
                                ></v-img>
                                <h3 v-else>
                                  {{
                                    shipment.assignedDocumentUser.firstname.charAt(
                                      0
                                    )
                                  }}
                                </h3>
                              </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content class="text-left">
                              <v-list-item-title style="font-size: 12px">
                                {{
                                  getDocumentUserDescription(
                                    shipment.assignedDocumentUser
                                  )
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle
                                style="font-size: 11px"
                                v-if="shipment.assignedDocumentUser"
                              >
                                Assigned Documentation User
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12" md="9">
                        <!-- <v-toolbar flat dense>
                         
                        </v-toolbar> -->
                        <ag-grid-vue
                          :autoSizeStrategy="{
                            type: 'fitGridWidth',
                            defaultMinWidth: 50,
                            columnLimits: [],
                          }"
                          groupDisplayType="groupRows"
                          :rowGroupPanelShow="'always'"
                          style="width: 100%; font-size: 11px"
                          :columnDefs="columns"
                          :rowData="shipment.shipmentDocumentTypes"
                          :isGroupOpenByDefault="isGroupOpenByDefault"
                          :defaultColDef="defaultColDef.def"
                          domLayout="autoHeight"
                          @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                      </v-col>
                    </v-row>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-card flat class="infoCards ma-0 py-0">
                <v-card-text class="ma-0 pa-0">
                  <v-list
                    dense
                    class="my-0 py-0"
                    style="max-height: 100%; overflow-y: auto"
                  >
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>widgets</v-icon>
                      Contents
                      <v-spacer></v-spacer>
                      <v-chip>
                        <v-text-field
                          placeholder="Search"
                          class="mb-1"
                          hide-details
                          rounded
                          clearable
                          dense
                          style="width: 300px"
                          v-model="searchContainers"
                        ></v-text-field>
                      </v-chip>
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-if="filterContainers.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No content found</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-for="container in filterContainers"
                      :key="container.id"
                    >
                    <v-list-item-action>
                      <v-chip small :color="containerStatusColor(container.status)">
                        <v-icon small left v-if="container.status">
                          {{ containerStatuses.find(x => x.name.toLowerCase() === container.status.toLowerCase()).icon }}
                        </v-icon>
                        {{ containerStatus(container.status) }}
                      </v-chip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="font-size: 12px"
                          v-if="container.containerNo"
                          >{{ container.containerNo }}</v-list-item-title
                        >
                        <v-list-item-title style="font-size: 12px" v-else
                          >-</v-list-item-title
                        >
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="container.sealNo"
                        >
                          Seal No: {{ container.sealNo }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="container.customerContainerRef"
                        >
                          Customer Reference:
                          {{ container.customerContainerRef }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="container.ctoNo"
                        >
                          System Reference: {{ container.ctoNo }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="container.grossWeight"
                        >
                          Gross Weight: {{ container.grossWeight }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="container.nettWeight"
                        >
                          Nett Weight: {{ container.nettWeight }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 11px"
                          v-if="container.totalPallets"
                        >
                          Pallets: {{ container.totalPallets }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { AgGridVue } from "ag-grid-vue";
import CategoryGroup from "../../Documentation/DocumentationComponents/CategoryGroup.vue";
import Status from "../../Documentation/DocumentationComponents/Status.vue";
import ResponsibleParty from "../../Documentation/DocumentationComponents/ResponsibleParty.vue";
export default {
  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },
  components: {
    AgGridVue,
    CategoryGroup,
    ResponsibleParty,
    Status,
  },
  data: () => ({
    containerStatuses: [
      { name: "Unallocated", value: null, icon: "warning", color: "orange darken-2" },
      { name: "Ghost", value: "ghost", icon: "category", color: "blue-grey" },
      { name: "Deleted", value: "error", icon: "error", color: "red" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "redPop" },
    ],
    columns: [
      {
        headerName: "Status",
        field: "status",
        enableRowGroup: true,
        cellRenderer: "Status",
        width: 75,
        cellClass: "d-flex align-center justify-center",
        valueGetter: (p) => (p.data ? p.data.status : null),
      },
      {
        headerName: "Document",
        field: "documentName",
        // rowGroup: true,
        // hide: true,
        enableRowGroup: true,
        valueGetter: (p) => (p.data ? p.data.locationDocument.name : null),
      },
      {
        headerName: "Reference",
        field: "reference",
      },
      //   {
      //     headerName: "Responsible Party",
      //     field: "responsiblePartyType",
      //     enableRowGroup: true,
      //     cellRenderer: "ResponsibleParty",
      //     width: 75,
      //     cellClass: "d-flex align-center justify-center",
      //   },

      {
        headerName: "Submitted Date",
        field: "submittedDate",
        sortable: false,
      },
      {
        headerName: "Completed Date",
        field: "completedDate",
        sortable: false,
      },

      {
        headerName: "Group",
        field: "bookingContainer.containerNo",
        sortable: false,
        cellRenderer: "CategoryGroup",
        cellClass: "d-flex align-center justify-center",
      },
    ],
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 25,
        cellClass: "d-flex align-center",
        cellStyle: { textAlign: "center", fontSize: "11px", fontWeight: "300" },
      },
    },
    draftDocuments: [],
    finalDocuments: [],
    gridApi: null,
    performance: {},
    partyTypes: [
      {
        name: "Bank",
        value: "bank",
        allowLink: false,
        icon: "account_balance",
        conditionalFields: ["letterOfCredit"],
      },
      {
        name: "Shipper",
        value: "shipper",
        allowLink: true,
        icon: "directions_boat_filled",
        linkedValue: "onBehalfShipper",
      },
      {
        name: "Forwarder",
        value: "forwarder",
        allowLink: true,
        icon: "fast_forward",
        linkedValue: "onBehalfForwarder",
      },
      {
        name: "Buyer",
        value: "buyer",
        allowLink: true,
        icon: "shopping_cart",
        linkedValue: "onBehalfBuyer",
      },
      {
        name: "Consignee",
        value: "consignee",
        allowLink: true,
        icon: "local_shipping",
        linkedValue: "onBehalfConsignee",
      },
      {
        name: "Courier Party",
        value: "courierParty",
        allowLink: false,
        icon: "local_post_office",
      },
      {
        name: "Notify Party",
        value: "firstNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "2nd Notify Party",
        value: "secondNotify",
        allowLink: false,
        icon: "notifications",
      },
    ],
    searchContainers: null,
    search: null,
    searchTimeout: null,
  }),
  computed: {
    listedParties() {
      let roles = this.partyTypes;
      let result = [];
      for (let role of roles) {
        if (this.shipment[role.value]) {
          let obj = {
            id: this.shipment[role.value].id,
            logo: this.shipment[role.value].logo,
            name: this.shipment[role.value].name,
            alias: this.shipment[role.value].alias,
            role: role.name,
            roleValue: role.value,
            linkedValue: role.linkedValue,
          };
          result.push(obj);
        }
      }
      return result;
    },
    filterContainers() {
      let result = this.shipment.containers ?? [];
      if (this.searchContainers) {
        result = result.filter(
          (x) =>
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.customerContainerRef &&
              x.customerContainerRef
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase()))
        );
      }
      return result;
    },
  },
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
    "shipment.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSharedDocuments();
          this.getPerformance();
        }
      },
    },
  },
  methods: {
    containerStatus(status) {
      if (status) {
      return status
      } else {
        return "Unknown";
      }
    },
    containerStatusColor(item) {
      if (item) {
        let find = this.containerStatuses.find(
          (x) => x.name.toLowerCase() === item.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    constructAddressLine(addressLine, address) {
      if (address) {
        switch (addressLine) {
          case 1:
            return [address.addressLine1, address.addressLine2]
              .filter(Boolean)
              .join(", ");
          case 2:
            return [address.addressLine3, address.addressLine4]
              .filter(Boolean)
              .join(", ");
          case 3:
            return [
              address.town,
              address.subdivision,
              address.country,
              address.postalCode,
            ]
              .filter(Boolean)
              .join(", ");
        }
      }
    },
    concatenateAddress(address) {
      let result = [
        this.constructAddressLine(1, address),
        this.constructAddressLine(2, address),
        this.constructAddressLine(3, address),
      ];
      return result.filter(Boolean).join(", ");
    },
    formatChangeType(type) {
      switch (type) {
        case "CHANGE_SHIPPER":
          return "Change of Shipper";
        case "ORIGINAL_SHIPPER":
          return "Original Shipper";
        case "CHANGE_ON_BEHALF_SHIPPER":
          return "Change of On Behalf Shipper";
        case "ORIGINAL_ON_BEHALF_SHIPPER":
          return "Original On Behalf Shipper";
        case "ORIGINAL_FORWARDER":
          return "Original Forwarder";
        case "CHANGE_FORWARDER":
          return "Change of Forwarder";
        case "ORIGINAL_ON_BEHALF_FORWARDER":
          return "Original On Behalf Forwarder";
        case "CHANGE_ON_BEHALF_FORWARDER":
          return "Change of On Behalf Forwarder";
        case "ORIGINAL_BUYER":
          return "Original Buyer";
        case "CHANGE_BUYER":
          return "Change of Buyer";
        case "ORIGINAL_ON_BEHALF_BUYER":
          return "Original On Behalf Buyer";
        case "CHANGE_ON_BEHALF_BUYER":
          return "Change of On Behalf Buyer";
        case "CHANGE_SHIPPING_LINE":
          return "Change of Shipping Line";
        case "CHANGE_POL":
          return "Change POL";
        case "ORIGINAL_POL":
          return "Original POL";
        case "CHANGE_CONSIGNEE":
          return "Change of Consignee";
        case "ORIGINAL_CONSIGNEE":
          return "Original Consignee";
        case "CHANGE_ON_BEHALF_CONSIGNEE":
          return "Change of On Behalf Consignee";
        case "ORIGINAL_ON_BEHALF_CONSIGNEE":
          return "Original On Behalf Consignee";
        case "ORIGINAL_FIRST_NOTIFY":
          return "Original First Notify";
        case "CHANGE_FIRST_NOTIFY":
          return "Change of First Notify";
        case "ORIGINAL_SECOND_NOTIFY":
          return "Original Second Notify";
        case "CHANGE_SECOND_NOTIFY":
          return "Change of Second Notify";
        case "ORIGINAL_COURIER_PARTY":
          return "Original Courier Party";
        case "CHANGE_COURIER_PARTY":
          return "Change of Courier Party";
        case "CHANGE_DESTINATION":
          return "Change Destination";
        case "ORIGINAL_DESTINATION":
          return "Original Destination";
        case "CHANGE_POD":
          return "Change POD";
        case "ORIGINAL_POD":
          return "Original POD";
        case "CHANGE_VESSEL":
          return "Change Vessel";
        case "ORIGINAL_VESSEL":
          return "Original Vessel";
        case "REMOVE_SHIPPER":
          return "Remove Shipper";
        case "REMOVE_ON_BEHALF_SHIPPER":
          return "Remove On Behalf Shipper";
        case "REMOVE_FORWARDER":
          return "Remove Forwarder";
        case "REMOVE_ON_BEHALF_FORWARDER":
          return "Remove On Behalf Forwarder";
        case "REMOVE_BUYER":
          return "Remove Buyer";
        case "REMOVE_ON_BEHALF_BUYER":
          return "Remove On Behalf Buyer";
        case "REMOVE_CONSIGNEE":
          return "Remove Consignee";
        case "REMOVE_ON_BEHALF_CONSIGNEE":
          return "Remove On Behalf Consignee";
        case "REMOVE_FIRST_NOTIFY":
          return "Remove First Notify";
        case "REMOVE_SECOND_NOTIFY":
          return "Remove Second Notify";
        case "REMOVE_COURIER_PARTY":
          return "Remove Courier Party";
        case "ORIGINAL_PROFILE":
          return "Original Profile";
        case "CHANGE_PROFILE":
          return "Change Profile";
      }
    },

    getAddressAlias(id) {
      if (id) {
        let find = this.addresses.find((x) => x.id == id);
        if (find) {
          return find.alias;
        } else return "No assigned Document Processing Office";
      } else {
        return "No assigned Document Processing Office";
      }
    },
    getDocumentUserDescription(user) {
      if (user) {
        return user.firstname + " " + user.surname;
      } else {
        return "No assigned User";
      }
    },
    async getPerformance() {
      let totalDocuments = this.shipment.shipmentDocumentTypes.filter(x => x.status !== "Not Required").length;
      let completedDocuments = this.shipment.shipmentDocumentTypes.filter(
        (x) => x.status === "Complete"
      ).length;
      this.performance = {
        progress: Math.round((completedDocuments / totalDocuments) * 100),
      };
    },
    async getSharedDocuments() {
      this.finalDocuments = await this.$API.getSharedFinalDocuments(
        this.shipment.id
      );
      this.draftDocuments = await this.$API.getSharedDraftDocuments(this.shipment.id);
    },
    isGroupOpenByDefault() {
      return false;
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.tabContent ::v-deep .v-tabs-bar__content {
  background: var(--darkLayer) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}
.innerContent {
  max-height: calc(94vh) !important;
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: auto;
}
</style>